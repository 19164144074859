var $ = require("jquery");

var headerHeight = $("#header").height(),
  offset = 46;

var url = window.location.href,
  regexp = /#(.*)/gi,
  match = regexp.exec(url);

if (match) {
  var dataTarget = $('[data-slug="' + match[1] + '"]:first');

  if(dataTarget.length) {
    window.history.scrollRestoration = "manual";
    
    setTimeout(function() {
      var scrollTarget = dataTarget.closest(".color-banner, .container, .center-block").offset().top;
      $("html, body").scrollTop(scrollTarget - headerHeight - offset);
      
      if(dataTarget.is('[data-toggle="tab"]')) {
        dataTarget.tab("show");

        // Mobile collapse tabs
        if (window.matchMedia('(max-width: 991px)').matches) {
          var collapseContent = $(dataTarget.attr('href')).children('.collapse').collapse('show');
          
          if (collapseContent.length) {
            $('html, body').scrollTop(collapseContent.closest('.tab-pane').prev('h3').offset().top - 95);
          }
        }
      }
    }, 50)
  }
}