var $ = require('jquery');
require('bootstrap-sass');
require('slick-carousel');
var ScrollBooster = require('scrollbooster');
require('./gtm');
require('./deep-links');

var Cookies = require('js-cookie');

// Alert Banner
(function () {
  var banner = $('#header .alert-banner');
  var content = $('#content');
  var navbar = $('#navbar');
  var navContainer = $('#header .nav-container');
  var key = banner.attr('data-alert-id');
  
  if (sessionStorage.getItem(key) === 'true') {
    return;
  }
  
  function adjustHeight(height) {
    var mobileNav = matchMedia('(max-width: 991px)').matches;
    
    content.css('margin-top', height || '');
    navbar.css('max-height', height && mobileNav ? 'calc(100vh - ' + (height + 77) + 'px)' : '');
    navContainer.css('height', height && mobileNav ? 'calc(100vh - ' + (height + 77) + 'px)' : '');
  }
  
  function onResize(e) {
    adjustHeight(banner.outerHeight());
  }
  
  banner.on('closed.bs.alert', function (e) {
    sessionStorage.setItem(key, 'true');
    if (window.ResizeObserver) {
      resizeObserver.unobserve(banner[0]);
    } else {
      $(window).off('resize', onResize);
    }
    adjustHeight();
  });

  onResize();
  
  if (window.ResizeObserver) {
    var resizeObserver = new ResizeObserver(function () {
      onResize();
    });
    
    resizeObserver.observe(banner[0]);
  } else {
    $(window).on('resize', onResize);
    setTimeout(onResize, 500);
    setTimeout(onResize, 1000);
  }
}());


// Now Open lightbox
document.addEventListener('DOMContentLoaded', function(e) {
  var nowOpenCookie = Cookies.get('now_open'); // Check Now Open cookie

  if (!parseInt(nowOpenCookie)) {
    $('.lightbox').show();
  }
});

$('.js-now-open').on('click', function(e) {
  Cookies.set('now_open', '1', { expires: 1 }); // Set Now Open cookie
  $('.lightbox').fadeOut();
});


// Prevent empty site search submit
$('#header form.nav-cta').on('submit', function (e) {
  if (!/\S/.test($(this).find('input[name="query"]').val())) {
    e.preventDefault();
  }
});

// Donations lightbox
document.addEventListener('DOMContentLoaded', function(e) {
  var donationsCookie = Cookies.get('donations'); // Check Donations cookie

  if (!parseInt(donationsCookie)) {
    $('.lightbox-donations').show();
  }
});

$('.js-donations').on('click', function(e) {
  closeDonationLightbox();
});

// Donations close lightbox event listener
$('.lightbox-donations').on('click', function (e) {
  if (e.target === e.currentTarget) {
    closeDonationLightbox();
  }
})

function closeDonationLightbox() {
// set cookie
// or sessionStorage: window.sessionStorage.setItem('hide-donation-lightbox', true);
// Cookies.set('donations', '1', { expires: 1 }); // Set Donations cookie
$('.lightbox-donations').fadeOut();
}


// Mobile navigation
(function () {
  var searchButton = $('#header button[data-target="#nav-mobile-search"]');

  $('#navbar').on('show.bs.collapse', function (e) {
    if (e.target == e.currentTarget) {
      $('body').addClass('modal-open');
    
      searchButton.addClass('active');
      setTimeout(function () {
        searchButton.addClass('in');
      }, 0);
    }
  }).on('hide.bs.collapse', function (e) {
    if (e.target == e.currentTarget) {
      $('body').removeClass('modal-open');
    
      searchButton.removeClass('in');
      $('#nav-mobile-search').collapse('hide');
    }
  })
  .on('hidden.bs.collapse', function (e) {
    if (e.target == e.currentTarget) {
      searchButton.removeClass('active');
    }
  });
}());


$('#nav-mobile-search').on('shown.bs.collapse', function (e) {
  $(this).find('input[name="query"]').get(0).focus();
});


// Custom accordions
$('body').on('show.bs.collapse', function (e) {
  var target = e.target;
  var accordion = $(e.target).attr('data-accordion');
  
  if (accordion) {
    $('[data-accordion="' + accordion + '"].in').not(e.target).collapse('hide');
  }
});


// Mobile select list tab and tab statistics display
$('body').on('change', 'select[data-toggle="tab"]', function (e) {
  $('a[data-toggle="tab"][href="' + $(this).val() + '"]').tab('show');
});

$('body').on('show.bs.tab', function (e) {
  var selector = $(e.target).attr('href');
  if (!selector) return;
  
  var counter = $('div.counter[data-related-tab="' + selector.slice(1) + '"]');
  
  if (counter.length) {
    $('div.counter.in').addClass('collapse').removeClass('in');
    counter.removeClass('collapse');
    setTimeout(function () { counter.addClass('in'); }, 50);
  }
  
  $('select[data-toggle="tab"] option[value="' + selector + '"]').parent('select').val(selector);
});


// Tab/Collapsible Combo functionality: render staff carousel after it was previously hidden
$('body').on('show.bs.collapse', function (e) {
  setTimeout(function () {
    $(e.target).find('.staff-list.carousel').slick('setPosition');
  }, 50);
});

$('body').on('shown.bs.tab', function (e) {
  $($(e.target).attr('href')).find('.staff-list.carousel').slick('setPosition');
});


// Prevent top margin on first child of collapsible elements to prevent jumping
$('h3.toggle + .collapse .wysiwyg').filter(function () { return /^\s*</.test($(this).html()) }).addClass('no-top-margin');


var mediaQueryXs = window.matchMedia('(max-width: 767px)');
var mediaQuerySm = window.matchMedia('(min-width: 768px) and (max-width: 991px)');

function toggleLatestNews(media) {
  $('.latest-news .news-previews').slick(media.matches ? {
    dots: true
  } : 'unslick');
}

mediaQueryXs.addListener(toggleLatestNews);

if (mediaQueryXs.matches) {
  toggleLatestNews(mediaQueryXs);
}


$('.staff-list.carousel').each(function () {
  var staffList = $(this);
  var staffItems = staffList.find('.staff-info');

  if (staffItems.length > 2) {
    staffList.slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  } else if (staffItems.length == 2) {
    var options = { infinite: true, slidesToShow: 1, slidesToScroll: 1, dots: true };

    if (mediaQueryXs.matches) {
      staffList.slick(options);
    }

    mediaQueryXs.addListener(function (media) {
      staffList.slick(media.matches ? options : 'unslick');
    });
  }
});




// Video functionality
var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var vimeoTag = document.createElement('script');
vimeoTag.src = 'https://player.vimeo.com/api/player.js';
$("head").append(vimeoTag);

var YouTubeAPI = $.Deferred(function (dfd) {
  window.onYouTubeIframeAPIReady = function () {
    dfd.resolve(YT);
  };
});

var videoModal;
var videoContainer;

$('body').on('click', 'a.video-preview', function (e) {

  e.preventDefault();

  videoContainer = $(this);

  var re = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/;
  
  var a = $(this).attr('href').match(re);
  
  if (a == null || !a[6] ) return;

  var videoId = a[6];

  //var match = $(this).attr('href').match(/\/watch\?v=([^&\s]*)/);
  //if (!match[1]) return;
  //var videoId = match[1];
  
  videoModal = $('<div class="modal fade" tabindex="-1" data-keyboard="true" role="dialog"><div class="box"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button><div class="embed-responsive embed-responsive-16by9"><div id="video-modal"></div></div></div></div>').appendTo('body');
  videoModal.modal('show');
  
  var player;

  var isPlaying = false; 

  YouTubeAPI.done(function (YT) {    
    if (a[3].indexOf('youtu') > -1) {
      player = new YT.Player('video-modal', {
        width: '854',
        height: '480',
        videoId: videoId,
        playerVars: {
          autoplay: 1,
          rel: 0,
        },
        events: {
          onStateChange: function (event) {
            switch (event.data) {
              case YT.PlayerState.PLAYING:
                if (!isPlaying) {
                  isPlaying = true;
                  dataLayer.push({
                    event: 'video_start',
                    video_id: videoId
                  });
                } 
                break;
              case YT.PlayerState.ENDED:
                if (isPlaying) {
                  dataLayer.push({
                    event: 'video_complete',
                    video_id: videoId
                  });
                  isPlaying = false;
                } 
                break;
            }
          }
        }
      });
    }
  });

  if ((a[3].indexOf('vimeo') > -1) ) {
    var options = {
      id : videoId,
      autoplay : true
    }
    player = new Vimeo.Player('video-modal', options);

    player.on('play', function() {
      dataLayer.push({
        event: 'video_start',
        video_id: videoId
      });
    });

    player.on('ended', function() {
      dataLayer.push({
        event: 'video_complete',
        video_id: videoId
      });
    });

  }

});

$('body').on('hidden.bs.modal', '.modal', function() { 
  $('.modal').remove();
  if (videoContainer != null)videoContainer.focus(); 
});




// Staff filter
var staffFilter = ({
  init: function () {
    this.form = $('form.staff-filter');
    this.searchInput = this.form.find('input[name="search"]');
    this.categorySelect = this.form.find('select[name="category"]');
    
    this.staffItems = $('.staff-info');
    
    this.noResultsMessage = $('<p class="text-center text-lg">No Results Found</p>');
    
    var timeout;
    var that = this;
    
    that.form.on('submit', function (e) {
      e.preventDefault();
    });
    
    this.form.on('keyup', 'input[name="search"]', function (e) {
      if (timeout) return;
      // clearTimeout(timeout);
      timeout = setTimeout(function () {
        timeout = null;
        that.filter();
      }, 250);
    });
    
    that.form.on('change', 'select[name="category"]', $.proxy(this, 'filter'));
    
    return this;
  },
  
  filter: function () {
    var search = this.searchInput.val();
    var category = this.categorySelect.val();
    
    if (!search && !category) {
      this.staffItems.css('display', '');
      this.noResultsMessage.remove();
      return;
    }
    
    // console.log('filter(%o, %o)', search, category);
    
    var searchRegex = new RegExp(search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&').replace(/\s+/g, '.*?'), 'i');
    
    var results = this.staffItems.hide().filter(function () {
      var $this = $(this);
      
      if (search) {
        if (!searchRegex.test($this.attr('data-name'))) {
          return false;
        }
      }
      
      if (category) {
        if (('|' + $this.attr('data-filter').replace('&amp;', '&') + '|').indexOf('|' + category + '|') == -1) {
          return false;
        }
      }
      
      return true;
    }).css('display', 'block');
    
    if (results.length) {
      this.noResultsMessage.remove();
    } else {
      this.staffItems.parent().after(this.noResultsMessage);
    }
    
    $('body').trigger('doctor-search', category);
  }
}).init();


// Third Party Form
jQuery('.third-party-form form').each(function () {
  var form = $(this);
  var email = form.find('input[type="email"]');

  form.attr('novalidate', 'novalidate');

  form.find('input[type="text"], input[type="email"]').each(function () {
    this.placeholder = this.value;
    this.setAttribute('aria-label', this.value.replace(/\*\s*$/, ' (required)'));
    if (this.type === 'email') {
      this.required = true;
      this.setAttribute('pattern', '.+(?:\\.[a-zA-Z]{2,})');
    }
    this.removeAttribute('value');
    this.removeAttribute('onfocus');
    this.removeAttribute('onblur');
    this.onfocus = this.onblur = null;
  });

  email.on('input', function (e) {
    if (this.validity.valid) {
      email.removeAttr('aria-invalid').removeAttr('aria-describedby').next('.error-message').remove();
    }
  });

  form.on('submit', function (e) {
    if (!email.get(0).validity.valid) {
      e.preventDefault();
      var error_id = (email.attr('id') || 'email') + '-error';
      email.attr('aria-invalid', true);
      email.attr('aria-describedby', error_id);
      email.next('.error-message').remove();
      email.after('<div class="error-message" id="' + error_id + '">Please enter a valid email address: name@domain.com</div>');
      email.focus();
    }
  });
});


// Centennial Page
jQuery('.centennial-carousel').slick({
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  dots: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        centerMode: false,
        variableWidth: false
      }
    }
  ]
});

$('body').on('click', '.timeline--decade > footer .btn-next', function (e) {
  var timeline = $(e.target).closest('.timeline');
  var nextTab = timeline.find('.nav-tabs:eq(0) > li.active').next('li').find('> a');
  nextTab.tab('show');
  $('html, body').animate({ scrollTop: timeline.offset().top - $('#header').outerHeight() }, 600);
});

function loadImages(element) {
  $(element).find('img[data-src]:not([src])').attr('src', function (index) {
    return $(this).attr('data-src');
  });
}

if (document.querySelector('.timeline')) {
  var scb = window.scb = new ScrollBooster({
    viewport: document.querySelector('.timeline .viewport'),
    content: document.querySelector('.timeline .viewport .content'),
    direction: 'horizontal',
    scrollMode: 'transform'
  });

  $('body').on('show.bs.tab', '.timeline a[data-toggle="tab"]', function (e) {
    // Scroll the tab bar
    var targetItem = $(e.target).closest('li');
    var previousItem = $(e.target).closest('li').prev();
    var listItem = (previousItem.length > 0 ? previousItem : targetItem).get(0);
    var offsetLeft = listItem.offsetLeft;
    var maxScroll = scb.content.width - scb.viewport.width;
    scb.scrollTo({ x: Math.max(0, Math.min(maxScroll, offsetLeft)) });
  
    // Load images from this and next tab
    loadImages($(e.target).attr('href'));
    loadImages($(e.target).closest('li').next('li').find('a[data-toggle="tab"]').attr('href'));
  });
  
  setTimeout(function () {
    loadImages($('.timeline--decade:eq(1)'));
  }, 1000);
}
