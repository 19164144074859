var $ = require('jquery');

$('body').on('click', 'a[data-gtm-event]', function (e) {
  var eventName = $(this).attr('data-gtm-event');
  var data = { event: eventName };
  
  if (eventName == 'event_signup') {
    var eventFilterCategory = $('form.event-filter select[name="category"]').val();
    
    if (eventFilterCategory) {
      data.event_category = eventFilterCategory;
    }
  }
  
  dataLayer.push(data);
});

$('body').on('click', 'a[href^="tel:"]', function (e) {
  dataLayer.push({
    event: 'call_click',
    phone_number: $(this).text()
  });
});

$('body').on('click', 'a[href^="mailto:"]:not([data-gtm-event])', function (e) {
  dataLayer.push({
    event: 'email_contact'
  });
});

$('body').on('click', 'a[data-gtm-pdf-name]', function (e) {
  dataLayer.push({
    event: 'pdf_download',
    pdf_name: $(this).attr('data-gtm-pdf-name')
  });
});

$('body').on('doctor-search', function (e, filterType) {
  var data = { event: 'doctor_search' };
  
  if (filterType) {
    data.filter_type = filterType;
  }
  
  dataLayer.push(data);
});
